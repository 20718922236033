import SiteWrapper from "../../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../../assets/images/pagerduty_dxable_og.png";

import MdRenderer from "../../components/mdrender";
import * as md from "../../components/mdrender.module.sass";
import TitleBlock from "../../components/title";

import "./security-incident-response@gen.scss";

const GeneratedUseCasesSecurityIncidentResponse = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>
          PagerDuty活用例：セキュリティーインシデント対応
          ｜PagerDuty正規販売代理店（株）Digital Stacks
        </title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="PagerDutyは、チームが迅速に問題を解決し、セキュリティーの脅威に対する積極防衛を簡単に実行できます。 ぜひお試しください。"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          name="description"
          content="PagerDutyは、チームが迅速に問題を解決し、セキュリティーの脅威に対する積極防衛を簡単に実行できます。 ぜひお試しください。"
        />

        <meta
          property="og:title"
          content="PagerDuty活用例：セキュリティーインシデント対応 ｜PagerDuty正規販売代理店（株）Digital Stacks"
        />

        <meta
          property="og:description"
          content="PagerDutyは、チームが迅速に問題を解決し、セキュリティーの脅威に対する積極防衛を簡単に実行できます。 ぜひお試しください。"
        />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/use-cases/security-incident-response/"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/use-cases/security-incident-response/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <TitleBlock
        classNames="md:mb-[80px] mb-[40px]"
        textYellow="Solution"
        textGreen="PagerDuty活用例：セキュリティーインシデント対応 "
        overview=""
      />

      <div className="itoperationsWrapper">
        <div className="itoperations-container">
          <div className="mt-0 center-1">
            <h3 className="center-title mb-[40px]">
              脅威への迅速な対応、リアルタイムな運用によるセキュリティー
            </h3>
            <p className="text-center md:px-0">
              PagerDuty for Security
              Opsを使用することで、セキュリティーチームは開発チームや運用チームと同じ信頼性の高いプラットフォームを活用し、チーム間の可視性を高め、円滑なコラボレーションを実現できます。さらに、PagerDutyの豊富なセキュリティーパートナーのエコシステムにより、クラウドセキュリティー、アプリケーションセキュリティー、SIEM、SOAR、脆弱性管理など、既存のツールも活用することができます。
            </p>
          </div>
          <div className="text-center mt-[100px]">
            <h3 className="relative center-title max-w-max px-[25px] md:px-[0px]">
              リアルタイムな運用によるセキュリティー
            </h3>
          </div>
          <div className="py-0 my-0 bg-transparent three-col-blog-card-image-grey mt-[50px] mb-[100px]">
            <div className="card">
              <div className="icon-section mb-[30px] min-h-[100px]">
                <svg
                  className="w-[80px] aspect-square"
                  xmlns="http://www.w3.org/2000/svg"
                  width="{80}"
                  height="{80}"
                  viewBox="0 0 80 80"
                  fill="none"
                >
                  <path
                    d="M16.7338 64.3456H29.3327C30.217 64.3456 30.9336 63.6816 30.9336 62.8619V44.1559C30.9336 43.3363 30.217 42.6716 29.3327 42.6716H16.7338C15.8497 42.6716 15.1328 43.3363 15.1328 44.1559V62.862C15.1328 63.6816 15.8497 64.3456 16.7338 64.3456Z"
                    fill="#50B448"
                  />
                  <path
                    d="M39.7048 36.8878C38.8204 36.8878 38.1035 37.5525 38.1035 38.3716V62.8619C38.1035 63.6816 38.8204 64.3456 39.7048 64.3456H52.3034C53.1877 64.3456 53.9046 63.6816 53.9046 62.8619V38.3716C53.9046 37.5525 53.1877 36.8878 52.3034 36.8878H39.7048Z"
                    fill="#50B448"
                  />
                  <path
                    d="M61.0742 27.8791V62.862C61.0742 63.6817 61.7911 64.3458 62.6752 64.3458H75.2741C76.1581 64.3458 76.8753 63.6817 76.8753 62.862V27.8791C76.8753 27.0594 76.1581 26.3953 75.2741 26.3953H62.6752C61.7911 26.3953 61.0742 27.0594 61.0742 27.8791Z"
                    fill="#50B448"
                  />
                  <path
                    d="M77.4103 71.1084H8.89094V2.58906V0H0V2.58906V71.1084V77.4097V80H2.58969H8.89094H77.4103H80V71.1084H77.4103Z"
                    fill="#50B448"
                  />
                  <path
                    d="M35.0348 18.598L48.0172 31.5137L66.6788 12.9473L70.9391 17.1856L73.8175 0L56.5439 2.86437L60.8037 7.10266L48.0172 19.8242L35.0348 6.90797L15.1328 26.7072L21.0077 32.5525L35.0348 18.598Z"
                    fill="#50B448"
                  />
                </svg>
              </div>
              <p className="mt-0 text-center">
                安全なデジタル運用のためのハブ：一貫したプロセスと意識向上による効率化を推進します。
              </p>
            </div>
            <div className="card">
              <div className="icon-section mb-[30px] min-h-[100px]">
                <svg
                  className="w-[80px] aspect-square"
                  xmlns="http://www.w3.org/2000/svg"
                  width="{80}"
                  height="{78}"
                  viewBox="0 0 80 78"
                  fill="none"
                >
                  <path
                    d="M28.8258 33.468L36.7971 39.6164C37.1042 42.0947 39.1933 44.0213 41.7549 44.0213C44.5289 44.0213 46.7767 41.7722 46.7767 38.9994C46.7767 38.8931 46.7524 38.7921 46.7447 38.6871L58.4257 24.8683C59.2397 23.9542 59.2552 22.6371 58.4589 21.9305C57.6639 21.2225 56.3596 21.3914 55.5453 22.3067L44.0692 34.5677C43.3728 34.2028 42.5946 33.9775 41.7547 33.9775C40.8575 33.9775 40.0266 34.2322 39.2969 34.6458L31.7928 29.4203C30.825 28.7074 29.3735 29.0375 28.5528 30.1566C27.7352 31.2725 27.8555 32.7563 28.8258 33.468Z"
                    fill="#50B448"
                  />
                  <path
                    d="M68.799 11.957C61.8927 5.04189 52.3021 0.753448 41.7552 0.756105V10.0266C49.7713 10.0292 56.9848 13.2628 62.2434 18.5125C67.4918 23.7699 70.7254 30.9845 70.7281 38.9994C70.7254 47.0141 67.4918 54.2288 62.2434 59.4863C56.9848 64.7372 49.7713 67.9708 41.7552 67.972C33.7406 67.9708 26.5259 64.7372 21.2684 59.4863C16.0187 54.2288 12.7851 47.0141 12.7826 38.9994C12.7838 36.3713 13.1423 33.8405 13.7926 31.43L17.5498 33.7702C18.0477 34.0788 18.6532 34.1441 19.1499 33.9405C19.6504 33.7344 19.9781 33.2902 20.0165 32.7577L21.6346 11.568C21.6781 10.9611 21.3452 10.3492 20.7796 9.99345C20.2087 9.64282 19.511 9.61064 18.9874 9.92173L0.683053 20.7142C0.221021 20.9844 -0.0325724 21.4733 0.00336515 22.0109C0.0391464 22.5513 0.363053 23.0645 0.862271 23.3742L5.65883 26.362C4.27258 30.3241 3.51086 34.5792 3.51086 38.9994C3.50836 49.5449 7.7993 59.1355 14.712 66.043C21.6193 72.9556 31.2087 77.2466 41.7554 77.2439C52.301 77.2466 61.8929 72.9556 68.7991 66.043C75.7116 59.1355 80.0027 49.5449 80.0001 38.9994C80.0026 28.4527 75.7115 18.862 68.799 11.957Z"
                    fill="#50B448"
                  />
                </svg>
              </div>
              <p className="mt-0 text-center">
                セキュリティーの衛生を向上させるための迅速な解決：重要なセキュリティーの脆弱性に関するフィードバックループを強化し、手作業に費やす時間を短縮します。
              </p>
            </div>
            <div className="card">
              <div className="icon-section mb-[30px] min-h-[100px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="{72}"
                  height="{80}"
                  viewBox="0 0 72 80"
                  fill="none"
                  className="w-[80px] aspect-square"
                >
                  <path
                    d="M35.9998 0L0.669922 20.3964C0.669922 27.8145 1.37398 34.2927 2.55383 40C9.84492 75.2689 35.9998 80 35.9998 80C35.9998 80 62.1543 75.2689 69.4455 40C70.6254 34.2927 71.3295 27.8147 71.3295 20.3964L35.9998 0ZM36.018 72.9255C36.0126 72.9236 36.0052 72.9211 35.9998 72.9194V65.4706V40H17.9949H10.2374C9.19523 35.3534 8.50164 30.0772 8.30086 24.0734L35.9995 8.0825H35.9998V16.1653V40H54.0107H61.7646C56.2552 64.5944 40.8945 71.3709 36.018 72.9255Z"
                    fill="#50B448"
                  />
                </svg>
              </div>
              <p className="mt-0 text-center">
                セキュリティーに関する説明責任の共有：セキュリティーを全ての人の仕事にすることで、組織を変革します。
              </p>
            </div>
          </div>
          <img
            className="p-4 mb-[100px] md:p-0"
            src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/Mid_Img_be9772be9f.svg?updated_at=2022-10-06T07:55:36.486Z"
            alt="automation"
          />
          <div className="mt-0 center-1 mb-[60px]">
            <h3 className="center-title mb-[40px]">
              セキュアなデジタル運用のためのハブ
            </h3>
            <p className="text-center md:px-0">
              PagerDuty Modern Incident
              Responseは、共通のプラットフォームを使用して、ソフトウェア開発者と運用エンジニアをリアルタイムのセキュリティーイベントに参加させることができます。PagerDuty
              Visibilityと豊富な統合エコシステムを使用して、運用中のアラートとアクティブなインシデントの環境全体の認識を向上させます。
            </p>
          </div>
          <div className="two-col-media-contents gap-[50px] mt-[0px] md:mt-[0px]">
            <div className="text-part">
              <p className="title">セキュリティー衛生向上のための迅速な解決</p>
              <p className="para">
                セキュリティーシグナルをアクションにつなげ、セキュリティープラットフォームをPagerDutyのエコシステムと補完し、チケット管理（ServiceNow、Remedy、Jiraなど）やチャット（Slack、Teamsなど）のワークフローを統合。セキュリティーをシフトレフトしフィードバックループを強化することが可能です。
              </p>
            </div>
            <div className="p-0 media-contents-part">
              <img
                id="fullImage"
                src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/IMAGE_4_2b27aac724.svg?updated_at=2022-09-30T07:49:00.423Z"
                alt="automation image"
              />
            </div>
          </div>
          <div className="two-col-media-contents gap-[50px] mt-[60px] md:mt-[60px]">
            <div className="text-part">
              <p className="title">セキュリティーの責任分担</p>
              <p className="para">
                PagerDuty Event
                Intelligenceで重要なシグナルを関連付け、トリアージし、セキュリティーを全員の仕事とすることができます。PagerDuty
                Analyticsでチームヘルスやセキュリティーアラートの頻発傾向を理解することが可能です。
              </p>
            </div>
            <div className="p-0 media-contents-part">
              <img
                id="fullImage"
                src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/IMAGE_5_97f542ed1f.svg?updated_at=2022-09-30T07:49:38.120Z"
                alt="auto motion iamge 3"
              />
            </div>
          </div>
          <div className="text-center mt-[100px] mb-[40px]">
            <h3 className="relative center-title max-w-max md:px-0 px-[25px]">
              現在お使いのツールとの連携
            </h3>
          </div>
          <div className="mt-0 center-2 mb-[80px]">
            <p className="text-center">
              今お使いのツールで動作するため、プロセスを変更する必要はありません。
            </p>
            <p className="text-center mt-[30px]">
              650以上のネイティブな統合機能と、拡張可能なPagerDuty
              APIを使用してワークフローを構築・カスタマイズする機能により、あらゆるツールからデータを統合し、ITインフラへのインサイトを提供します。強化されたEvent
              API
              v2は、全ての受信イベントを共通のフィールドに自動的に正規化します。
            </p>
            <p className="text-center mt-[30px]">
              双方向の拡張機能によりツール切り替えの手間はなく、インラインで問題を修正し、任意のChatOpsツールで運用関連のタスクを自動化可能。現在お使いのツールとよくなじみます。
            </p>
            <img
              className="p-0 mt-[30px] md:mt-[30px] md:p-0"
              src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/Group_3_6188389cea.png?updated_at=2022-09-30T03:50:53.636Z"
              alt="automation"
            />
          </div>
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedUseCasesSecurityIncidentResponse;
